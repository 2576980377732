@import url(https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#title{
  font-family: 'Bungee Shade', cursive;
  font-weight: bold;
  font-size: 40px;
}
#title-small{
  font-family: 'Bungee Shade', cursive;
  font-weight: bold;
  color: navy;
}
.col-centered{
  float: none;
 
}
body {
  font-family: Futura, sans-serif !important;
  background-color: #4fe0c8 !important;
  color: black !important;
}
.homepage{
  background: url(/static/media/tealPattern.b82265c7.jpeg) !important;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  

}
.homeDiv {
  height: 100vh;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  -webkit-align-content: center !important;
          align-content: center !important;
}
#titleName{
  font-family: 'Bungee Shade', cursive;
  font-weight: bold;
  font-size: 40px;
  margin: 0 auto !important;
  
}
#homeCard{
  background: rgba(255, 255, 255, 0.8);
  margin: 0 auto !important;
  height: 300px;
  width: 700px;
  border-radius: 20px;
  
 
}
.btn {
  background: navy !important;
  color: #4fe0c8 !important;
}

.btn:hover{
  background: #4fe0c8 !important;
  color: navy !important;
}
.a-text{
  color: #4fe0c8 !important; 
}
.a-text:hover{
  color: navy !important; 
}

/* About */ 
.bio{
  font-size: 22px;
}
#emily_photo {
  height: 300px;
  border-radius: 200px;
  padding: 0px !important;
  border: 5px solid navy;
}
#contact{
  padding: 0px !important;
  margin: 2px !important;
}
#contactIcon {
  width: 40px !important;
}
.color-text{
  color: navy !important;
}
#skillsdiv{
  margin: 0 auto !important;
  max-width: 900px !important;
}
.card{
  font-family: Futura, sans-serif;
  padding: 15px;
  margin: 20px;
  margin-right: 15px;
  margin-left: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  border: none !important;
  background: none !important;
  width: 115px !important;
}
#funFact{
  min-width: 20rem !important;
  margin: 5px;
}
#projectCard{
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 20px;
}
#mobileCard {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
img {
  margin: 0 auto;
  padding: 2px;
}
.projectIMG {
  width: 600px;
  border-radius: 5px;
}
.icon {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  padding: 3rem;
  color: navy;
  width: 64px;
}
.react-icons{
  width: 64px;
}
svg {
  color: navy;
  width: 64px;
  margin: 0 auto;
}
.singleIcon {
  color: navy;
  width: 64px;
  margin: 0 auto;
}
i {
  font-size: 64px;
}
.singleIcon:hover{
  color: white;
}

svg:hover {
  color: white;
  width: 64px;
}

#nohover{
  max-width: 40px !important;
  font-size: 40px;
}
#nohover:hover{
  color: navy;
}
.skills {
  margin: 30px;
  padding: 20px;
}

/* flipcard */

.flip-card {
  background-color: transparent;
  width: 380px;
  height: 440px;
  
  border-radius: 20px;
  -webkit-perspective: 1000px;
          perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-radius: 20px;
  text-align: center;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  border: 2px solid navy;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
#card-icon {
  color: white;
  width: 150px;
}
/* Style the front side (fallback if image is missing) */
.flip-card-front {
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

/* Style the back side */
.flip-card-back {
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.margin-center{
  margin: 0 auto;
}


@media (max-width: 1024px) {
  #homeCard {
    width: 100vh !important;
    height: 50vh !important;
  }
  
  #titleName{
    text-align: center;
  }
  #typingtext{
    font-size: 1.5rem !important;
  }
  #title{
    text-align: center;
  }
  .card{
    padding: 5px;
   
  }
  .mr-5 {
    margin-right: 0 !important;
  }
  #bioInfo {
    font-size: 16px !important;
    width: 100% !important;
    
  }
  #fun-facts {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  #info-div{
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    
  }
  #projectCard{
    max-width: 60rem !important;
    min-width: 10rem !important;
    margin: 0 auto;
    padding: 2em !important;
  }
  h2 {
    text-align: center;
  }
  .projectIMG{
    display: block;
  margin-left: auto;
  margin-right: auto;
  width: 105%;
    
  }
  #projectInfo {
    margin-right: auto !important;
    padding: 0px !important;
    margin-top: 1em;
    font-size: 16px !important;
  }
  
  #mobileCard{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  #techtitle{
    margin-left: 0 auto !important;
    margin-right: 10% !important;
    text-align: center;
    font-size: 30px;
    padding-top: 7px !important;
  }
  #techbox{
    min-width: 109% !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex: wrap 1 !important;
            flex: wrap 1 !important;
    -webkit-align-content: center !important;
            align-content: center !important;
    text-align: center;
    margin: 0 auto !important;
    margin-left: 0 auto !important;
    margin-right: 0 auto !important;
  }
  #techicon{
    margin-left: 1px !important;
    margin-right: 1px !important;
  }
  
  #desktop-facts{
    display: none;
    
    /* display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-content: center !important;
    margin: 0 auto !important; */
  }
 
  .padding-fix {
    padding-top: 4px;
  }
  #mobile-facts{
    display: block !important;
  }
  #funFact{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    color: white;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 25rem !important;
    min-width: 17rem !important;
    padding: 10px !important;
  }
  #card-icon {
    width: 40px;
  }
  #factTitle{
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  #factInfo {
    font-size: 14px !important;
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
 .singleIcon{
   font-size: 40px;
 }
 svg {
   width: 40px;
 }
 svg:hover {
  width: 40px;
}
 #skillsdiv {
   min-width: 20rem !important;
   display:-webkit-flex;
   display:flex ;
   -webkit-justify-content: center !important;
           justify-content: center !important;
   margin: 0 auto !important;
   margin-left: 0 auto !important;
   margin-right: 0 auto !important;
 }

}
@media(max-width: 375px){
  #skillsdiv{
    min-width: 120% !important;
    margin: 0 auto !important;
    margin-left: -28px !important;
  }
  #techbox {
    margin-left: -13px !important;
  }
}
@media(min-width:1025px){
  
  #mobile-facts{
    
    display: none !important;
  }

}
